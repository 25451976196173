import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function companyUseList(config) {

  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'company_id', label: 'ID', sortable: true },
    // { key: 'parent_id', label: '父ID', sortable: true },
    // { key: 'company_type', label: '业务角色(1,渠道客户,2集团公司,3供应商,4仓库方)   company_type', sortable: true },
    // { key: 'company_owner', label: '创建组织', sortable: true },
    // { key: 'register_code', label: '注册编码', sortable: true },
    // { key: 'company_code', label: '公司编码', sortable: true },
    { key: 'company_name', label: '名称', sortable: true },
    { key: 'erp_code', label: 'ERP编码', sortable: true },
    // { key: 'nick_name', label: '简称', sortable: true },
    // { key: 'region_area', label: '地理分区', sortable: true },
    // { key: 'province_id', label: '省', sortable: true },
    // { key: 'city_id', label: '城市', sortable: true },
    // { key: 'area_id', label: '地区', sortable: true },
    // { key: 'address', label: '通讯地址', sortable: true },
    // { key: 'telephone', label: '公司固定电话', sortable: true },
    // { key: 'email', label: '邮箱', sortable: true },
    // { key: 'fax', label: '传真', sortable: true },
    // { key: 'zipcode', label: '邮政编码', sortable: true },
    // { key: 'language', label: '语言', sortable: true },
    // { key: 'web_site', label: '公司网址', sortable: true },
    // { key: 'industry', label: '公司行业 industry_type', sortable: true },
    // { key: 'registration_date', label: '注册日期', sortable: true },
    // { key: 'corporation', label: '法人', sortable: true },
    // { key: 'registered_capital', label: '注册资金(万)', sortable: true },
    // { key: 'business_registration', label: '工商登记号', sortable: true },
    // { key: 'business_certificate', label: '生产经营许可证', sortable: true },
    // { key: 'uniform_social_credit_code', label: '统一社会信用代码', sortable: true },
    // { key: 'register_address', label: '注册地址', sortable: true },
    // { key: 'deparment_id', label: '负责部门ID', sortable: true },
    // { key: 'user_id', label: '负责人', sortable: true },
    // { key: 'company_class', label: '供应商分类', sortable: true },
    // { key: 'prepaid_amount', label: '预付款总金额', sortable: true },
    // { key: 'supply_class', label: '供应类别  supply_class', sortable: true },
    // { key: 'company_level', label: '公司等级  company_level', sortable: true },
    // { key: 'company_kind', label: '公司类别 company_kind', sortable: true },
    // { key: 'company_properties', label: '公司性质 company_properties', sortable: true },
    // { key: 'company_scale', label: '公司规模  company_scale', sortable: true },
    // { key: 'company_organization', label: '对应组织', sortable: true },
    // { key: 'attachments_USCC_id', label: '营业执照扫描件', sortable: true },
    // { key: 'attachments_GSC_id', label: '周年申报表/公司存续证明', sortable: true },
    // { key: 'attachments_IDCard', label: '法人身份证/护照', sortable: true },
    // { key: 'attachments_link', label: '产品链路证明', sortable: true },
    // { key: 'attachments_history', label: '历史交易资料', sortable: true },
    // { key: 'attachments_other', label: '其他资料', sortable: true },
    { key: 'status', label: '数据状态 ', sortable: true },
    // { key: 'state', label: '启用', sortable: true },
    // { key: 'attachments', label: '', sortable: true },
    { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人ID', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const limitOption = [1,2,3]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('company_id')
  const isSortDirDesc = ref(true)
  const routeType=ref(0)
  const companyName = ref('')
  const companyUserId = ref('')
  const companyStatus = ref('')
  const addTime = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey,routeType], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    if (routeType.value==0){
      routeType.value=config
    }
    store
        .dispatch('company/financeSearch', {
          search_key: searchKey.value,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
          companyName: companyName.value,
          companyStatus: companyStatus.value,
          addTimes:addTime.value,
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '企业列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    limitOption,
    routeType,
    refetchData,

    // 搜索条件
    companyName,
    companyUserId,
    companyStatus,
    addTime,
    // Extra Filters
  }
}
