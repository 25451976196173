<template>

  <div>
    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="9"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <div class="demo-spacing-0">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body" >
                  <span><strong>新增供应商请把供应商分组修改为BB专用 如果是VMI供应商则需要勾选vmi供应商</strong></span>
                  <br>
                  <span><strong>新增完成后需要自行更新供应商、渠道商ERP编码</strong> </span>
                </div>
              </b-alert>
            </div>

          </b-col>

          <b-col md="1">
            <b-form-input
                    v-model="routeType"
                    class="d-inline-block mr-1 hidden"
            />

          </b-col>
          <!-- Search -->
          <b-col
                  cols="12"
                  md="2"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--              <b-form-input
                      v-model="searchKey"
                      class="d-inline-block"
                      placeholder="搜索关键字..."
              />

              <b-button
                      variant="primary"
                      :to="{ name: 'apps-company-edit'}"
                      class="hidden"
              >
                <span class="text-nowrap">添加</span>
              </b-button>-->
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="供应商名称:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="companyName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="状态"
                    label-for="allot_type"
                    label-size="sm"
                >
                  <v-select
                      id="allot_type"
                      :options="getCodeOptions('company_status')"
                      :clearable="false"
                      v-model = "company_status_label"
                      class="select-size-sm"
                      @input="changeProductStatus($event)"
                      placeholder="选择状态"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建日期"
                    label-for="length"
                    label-size="sm"
                    class="mb-1"
                >
                  <flat-pickr
                      v-model="addTime"
                      class="form-control"
                      :config="{ mode: 'range'}"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(company_id)="data">
          #{{ data.item.company_id }}
        </template>

        <template #cell(erp_code)="data">
          <b-form-input
              id="erp_code"
              size="sm"
              v-model="data.item.erp_code_no"
          />
        </template>


        <!--        company_status-->
        <template #cell(status)="data">
          <b-badge
                  pill
                  :variant="`light-${getCodeColor('company_status', data.item.status)}`"
          >
            {{getCodeLabel('company_status', data.item.status)}}
          </b-badge>
        </template>

        <template #cell(user_id)="data">
          {{getCodeLabel('user', data.item.user_id)}}
        </template>

        <template #cell(create_time)="data">
          {{toTime(data.item.create_time)}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
                  @click="changeState(data.item)"
          >
            <b-badge
                    pill
                    :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{getCodeLabel('state', data.item.state)}}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
              variant="primary"
              class="mr-1"
              @click="save(data.item)"
          >
            保存
          </b-button>
<!--          <b-dropdown-->
<!--                  variant="link"-->
<!--                  no-caret-->
<!--          >-->

<!--            <template #button-content>-->
<!--              <feather-icon-->
<!--                      icon="MoreVerticalIcon"-->
<!--                      size="16"-->
<!--                      class="align-middle text-body"-->
<!--              />-->
<!--            </template>-->
<!--            -->
<!--            <b-dropdown-item :to="{ name: 'apps-company-view', query: { id: data.item.id ,type:type} }">-->
<!--              <feather-icon icon="FileTextIcon" />-->
<!--              <span class="align-middle ml-50">编辑企业</span>-->
<!--            </b-dropdown-item>-->
<!--          </b-dropdown>-->
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>


    </b-card>
  </div>
</template>

<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import {ref, onUnmounted, watch} from '@vue/composition-api'
  import companyUseList from './companyUseList'
  import companyStore from '../companyStore'
  import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
  import { useRouter } from '@core/utils/utils'
  import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
  import {getUserData} from "@/auth/utils";
  import CompanyPrepaidList from "@/views/apps/companyprepaid/CompanyPrepaidList";
  import Ripple from "vue-ripple-directive";
  import UserSelect from '@/views/apps/user/user-modal/UserSelect'
  import {useToast} from "vue-toastification/composition";

  export default {
    components: {
      CompanyPrepaidList,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      AttachmentUpload,
      UserSelect,
    },
    data() {
      return {
        type: '',
        user:{},
        companyid:0,
        advanced_search_modal:false,
        userId: "",
        userDepartment:"",
        company_status_label: "",
      }
    },
    directives: {
      Ripple,
    },
    methods: {
      changeState: function(data) {
        const state = 1 - data.state
        store.dispatch('company/state', {id: data.id, state: state}).then(res => {
          data.state = state
        })
      },
      getType: function() {
        return this.type
      },

    },

    setup() {
      const toast = useToast()
      // Register module
      if (!store.hasModule('company')) store.registerModule('company', companyStore)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule('company')) store.unregisterModule('company')
      })

      const router = useRouter()

      const advanced_search = function () {
        this.advanced_search_modal = this.advanced_search_modal ? false : true;
      }

      const changeProductStatus = function (event) {
        if (event != null) {
          this.company_status_label = event.label
          this.companyStatus = event.value
        }
      }

      const resetCondition = function () {
        this.companyName = ""
        this.companyStatus = ""
        this.company_status_label = ""
        this.addTime = ""
        this.refetchData()
      }

      const searchByCondition = function() {
        this.refetchData()
      }

      const save = function (params) {
        store.dispatch('company/save', params).then(res => {
          if (res.data.code==0){
            toast.success('数据已保存!')
          }else {
            toast.error(res.data.data)
          }
          refetchData()
        })
      }

      const {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        routeType,

        // 高级搜索
        companyName,
        companyUserId,
        companyStatus,
        addTime,

      } = companyUseList(router.route.value.query.type)


      return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        routeType,
        refetchData,
        // 高级搜索
        companyName,
        companyUserId,
        companyStatus,
        addTime,

        toTime,
        toDate,


        // Filter
        avatarText,
        getCodeOptions,
        getCode,
        getCodeLabel,
        getCodeColor,
        advanced_search,
        changeProductStatus,
        resetCondition,
        searchByCondition,
        save,
      }
    },
    watch: {
      $route(to, from) {
        this.type = this.$route.query.type
        this.routeType = this.$route.query.type
        this.advanced_search_modal = false
        this.resetCondition()
      }
    },
    created() {
      this.type = this.$route.query.type || 0
      const userData = getUserData()
      this.user = userData
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
